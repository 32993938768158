<template>
<!-- 
  Stepper에서 제외할 항목
  CSE0000002 : 신청중
  CSE0000003 : 신청완료
  CSE0000004 : 대여중
  CSE0000005 : 반납완료
-->
  <c-step
    stepperGrpCd="HEA_CLOSED_SPACE_EQUIP_STEP_CD"
    :param="param"
    :stepRejectItems="stepRejectItems"
    :currentStepCd="requestStepCd"
    v-model="requestStepCd"
    @getDetail="getDetail"
    @setId="setId"
    @closePopup="closePopup"
    @stepClick="stepClick"
  >
  </c-step>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'closedSpaceEquipDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopEquipmentRentId: '',
      }),
    },
  },
  data() {
    return {
      equipmentRent: {
        sopEquipmentRentId: '',  // 작업장비 대여 일련번호
        plantCd: '',  // 사업장 코드
        equipRentStatusCd: '',  // 진행단계
        requestDate: '',  // 신청일
        sopWorkPermitId: null,  // 작업허가서 일련번호
        permitNo: '',
        regUserId: '',  // 등록자 ID
        regUserName: '',  // 등록자 ID
        regUserDeptName: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        requestItems: [],
        deleteRequestItems: [],
        rentReturnItems: [],
      },
      stepUid: '',
      requestStepCd: 'CSE0000001',
      editable: true,
    };
  },
  watch: {
  },
  computed: {   //computed는 setter가 없으니 지정해줄 수 없음
    param() {
      return {
        sopEquipmentRentId: this.popupParam.sopEquipmentRentId,
        equipmentRent: this.equipmentRent,
        isOld: this.isOld,
        stepUid: this.stepUid
      }
    },
    isOld() {
      return Boolean(this.popupParam.sopEquipmentRentId)
    },
    stepRejectItems() {
      return [
        'CSE0000003'
      ]
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.hea.cse.gives.get.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopEquipmentRentId);
        this.$http.type = 'GET'; 
        this.$http.request((_result) => {
          this.$_.extend(this.equipmentRent, _result.data);
          if (this.equipmentRent.equipRentStatusCd !== 'CSE0000003') {
            this.requestStepCd = this.$_.clone(this.equipmentRent.equipRentStatusCd);
          } else {
            this.requestStepCd = 'CSE0000002';
          }

          if (this.equipmentRent.requestItems && this.equipmentRent.requestItems.length > 0) {
            this.$_.forEach(this.equipmentRent.requestItems, item => {
              item.requestRentPeriod = [item.requestRentStartDate, item.requestRentEndDate]
            })
          }
        
          if (this.equipmentRent.rentReturnItems && this.equipmentRent.rentReturnItems.length > 0) {
            this.$_.forEach(this.equipmentRent.rentReturnItems, item => {
              item.rentPeriod = [item.rentStartDate, item.rentEndDate]
            })
          }
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        this.equipmentRent.regUserId = this.$store.getters.user.userId;
        this.equipmentRent.regUserName = this.$store.getters.user.userName;
        this.equipmentRent.regUserDeptName = this.$store.getters.user.deptName;
        this.requestStepCd = 'CSE0000001';
      }
    },
    setId(sopEquipmentRentId) {
      this.$set(this.popupParam, 'sopEquipmentRentId', sopEquipmentRentId);
      this.getDetail();
    },
    stepClick() {
      this.stepUid = uid();
    },
    closePopup() {
      this.$emit('closePopup')
    },
  }
};
</script>
  